<style scoped lang="less">
.t_work_online_add {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
    .label {
      background: #f2f2f2;
      padding: 10px;
      margin-bottom: 20px;
      span {
        font-weight: 500;
      }
    }
    .form_item_12 {
      width: 60%;
      min-width: 420px;
    }
    .form_item_8 {
      width: 33.33%;
      min-width: 350px;
      display: inline-block;
    }
    .form_item_6 {
      width: 24.98%;
      min-width: 300px;
      display: inline-block;
    }
  }
}
</style>
<template>
  <div class="t_work_online_add">
    <div class="stu-module-header">
      <div class="stu-module-title">在线作业</div>
      <div v-if="!noSaveBtn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="back">取消</el-button>
      </div>
    </div>
    <div class="content">
      <el-form
        :model="form"
        size="medium"
        label-width="120px"
        ref="add_work_form"
        :rules="rules"
      >
        <div class="label">
          <span>作业信息</span>
        </div>
        <el-form-item label="在线作业标题" prop="workName" class="form_item_12">
          <el-input
            v-model="form.workName"
            clearable
            :maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="关联试卷" prop="paperId" class="form_item_12">
          <el-select  v-model="form.paperId" clearable>
            <el-option
              v-for="item in paperOptions"
              :key="item.id"
              :label="item.paperName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="在线作业要求" prop="workDesc" class="form_item_12">
          <el-input
            type="textarea"
            v-model="form.workDesc"
            clearable
            :maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="出卷次数" prop="rollNum" class="form_item_12">
          <el-input-number
            v-model="form.rollNum"
            :min="10"
            :precision="0"
            :max="50"
          ></el-input-number>
          <span> {{ paperMessage }}</span>
        </el-form-item>
        <el-form-item label="权重" prop="weight" class="form_item_12">
          <el-input-number
            v-model="form.weight"
            :precision="0"
            :min="0"
            :max="99999"
          ></el-input-number>
        </el-form-item>
        <div class="label">
          <span>卷面信息</span>
        </div>
        <el-form-item label="答题次数" prop="answerNum" class="form_item_8">
          <el-input-number
            v-model="form.answerNum"
            :min="-1"
            :max="99999"
            :precision="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="试卷答题时间"
          prop="answerTime"
          class="form_item_8"
        >
          <el-input-number
            v-model="form.answerTime"
            :min="0"
            :max="99999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="禁止复制粘贴" prop="copyFlag" class="form_item_8">
          <el-radio-group  v-model="form.copyFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="取消文本框文件按钮"
          prop="buttonFlag"
          label-width="160px"
          class="form_item_8"
          required
        >
          <el-radio-group v-model="form.buttonFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="label">
          <span>答题完成后设置</span>
        </div>
        <el-form-item
          label="显示参考答案"
          label-width="170px"
          prop="showFlag"
          class="form_item_6"
          required
        >
          <el-radio-group v-model="form.showFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="显示客观题成绩"
          prop="scoreFlag"
          label-width="170px"
          class="form_item_6"
          required
        >
          <el-radio-group v-model="form.scoreFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label-width="170px"
          label="显示可查看历史作业"
          prop="historyFlag"
          class="form_item_6"
          required
        >
          <el-radio-group v-model="form.historyFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label-width="170px"
          label="是否允许撤回作答记录"
          prop="shiftFlag"
          class="form_item_6"
          required
        >
          <el-radio-group v-model="form.shiftFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noSaveBtn: { type: Boolean, default: false },
  },
  name: "t_work_online_add",
  created() {
    this.queryAll();
  },
  activated() {
    this.init()
  },
  components(){
    this.init()
  },
  data() {
    return {
      paperMessage: "(>=10, <= 50)",
      form: {
        workName: "",
        id: 0,
        paperId: "", // 关联试卷
        workDesc: "", // 作业要求
        rollNum: 10, // 出卷次数
        weight: 0, // 权重
        answerNum: 0, // 答题次数
        answerTime: 90, // 答题时间
        copyFlag: "0", // 禁止复制粘贴
        buttonFlag: "0", // 取消文本框文件按钮
        showFlag: "0", // 显示参考答案
        scoreFlag: "1", // 显示客观题成绩
        historyFlag: "1", // 显示可查看历史作业
        shiftFlag: "0", // 是否允许撤回作答记录
      },
      rules: {
        workName: [
          { required: true, message: "请输入在线作业标题", trigger: "blur" },
        ],
        paperId: [
          { required: true, message: "请选择关联试卷", trigger: "change" },
        ],
        rollNum: [
          { required: true, message: "请输入出卷次数", trigger: "blur" },
        ],
        weight: [{ required: true, message: "请输入权重", trigger: "blur" }],
        answerNum: [
          { required: true, message: "请输入试卷答题次数", trigger: "blur" },
        ],
        answerTime: [
          { required: true, message: "请输入试卷答题时间", trigger: "blur" },
        ],
      },
      paperOptions: [], // 关联试卷 options
    };
  },
  computed: {
    course(){
      return this.$store.getters.getTCourse;
    }
  },
  methods: {
    init() {
      const id = this.$route.query.id;
      //this.loading = true;
      if(id!=null&&id!=""){
        this.$axios_supermallData.get("/hnjxjy-core/eduCourseWorkSetTea/previewHomeWorkByWorkId?id="+id)
            .then((res) => {
              //     this.loading = false;
              if (res.data.code==2000) {
                this.form = res.data.data;
                this.form.buttonFlag=res.data.data.buttonFlag.toString()
                this.form.copyFlag=res.data.data.copyFlag.toString()
                this.form.showFlag=res.data.data.showFlag.toString()
                this.form.scoreFlag=res.data.data.scoreFlag.toString()
                this.form.shiftFlag=res.data.data.shiftFlag.toString()
                this.form.historyFlag=res.data.data.historyFlag.toString()
              }else{
              }
            })
            .catch((res) => {
              //      this.loading = false;
            });
      }

    },
    save() {

      this.$refs["add_work_form"].validate((valid) => {

        if (!valid) return false;
        if(this.form.id>0){
          this.$axios_supermall.post("hnjxjy-core/eduCourseWorkSetTea/updateEduCourseWorkSetById",
              {
                courseId: this.course.id, //课程id
                paperId: this.form.paperId, //试卷id
                id: this.form.id, //id
                workName: this.form.workName, //作业名称
                workDesc: this.form.workDesc, //作业要求
                rollNum: this.form.rollNum, //出卷次数
                weight: this.form.weight, //权重
                answerNum: this.form.answerNum, //答题次数 -1表示无限次
                copyFlag: this.form.copyFlag, //禁止复制粘贴 0否 1是
                buttonFlag: this.form.buttonFlag, //取消文本框文件按钮 0否 1是
                showFlag: this.form.showFlag, //显示参考答案 0否 1是
                scoreFlag: this.form.scoreFlag, //显示客观题成绩 0否 1是
                historyFlag: this.form.historyFlag, //显示可查看历史作业 0否 1是
                shiftFlag: this.form.shiftFlag, //是否允许撤回作答记录 0否 1是
                answerTime: this.form.answerTime //答题时间
              } ).then(resp => {
            if (resp.data.code == 2000){
              this.$message.success("操作完成");

              this.$router.push("/teacher/t/work/online");
            }else {
              this.$message.warning(resp.data.message);
            }
          })
        }else{
          this.$axios_supermall.post("hnjxjy-core/eduCourseWorkSetTea/addEduCourseWorkSetByCourseId",
              {
                courseId: this.course.id, //课程id
                paperId: this.form.paperId, //试卷id
                workName: this.form.workName, //作业名称
                workDesc: this.form.workDesc, //作业要求
                rollNum: this.form.rollNum, //出卷次数
                weight: this.form.weight, //权重
                answerNum: this.form.answerNum, //答题次数 -1表示无限次
                copyFlag: this.form.copyFlag, //禁止复制粘贴 0否 1是
                buttonFlag: this.form.buttonFlag, //取消文本框文件按钮 0否 1是
                showFlag: this.form.showFlag, //显示参考答案 0否 1是
                scoreFlag: this.form.scoreFlag, //显示客观题成绩 0否 1是
                historyFlag: this.form.historyFlag, //显示可查看历史作业 0否 1是
                shiftFlag: this.form.shiftFlag, //是否允许撤回作答记录 0否 1是
                answerTime: this.form.answerTime //答题时间
              } ).then(resp => {
            if (resp.data.code == 2000){
              this.$message.success("操作完成");
              this.$refs.add_work_form.resetFields()
              this.$router.push("/teacher/t/work/online");
            }else {
              this.$message.warning(resp.data.message);
            }
          })
        }

      });
    },
    //查询试卷
    queryAll() {
      this.$axios_supermall.get("/hnjxjy-core/eduCourseWorkSetTea/queryAllTestByCourseId?courseId="+this.course.id).then(resp=>{
        if (resp.data.code == 2000) {
          this.paperOptions = resp.data.data;
        }
      })
    },
    back() {
      this.$refs.add_work_form.resetFields()
      this.$router.push("/teacher/t/work/online");
    },
  },
};
</script>
